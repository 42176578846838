import Button from 'components/UI/Button';
import { useNavigate } from 'react-router-dom';
import classes from 'components/Layout/styles.module.scss';
import buildMinIcon from 'assets/img/pic/build-min.png';
import { useUnit } from 'effector-react';
import { $storeList } from 'models/store';
import { useKeycloak } from "@react-keycloak/web";
import { delToken } from 'models/user';
import { IStore } from 'dto/store.dto';
import useLocalStorage from 'use-local-storage';

function Home() {
	const { keycloak } = useKeycloak();
	const [store, setStore] = useLocalStorage<IStore | undefined>(`store`, undefined);
	const navigate = useNavigate();
	const storeList = useUnit($storeList);


	const handleYes = () => {
		navigate('/passports');
	};

	const handleExit = () => {
		setStore(undefined)
        delToken()
        keycloak.logout(); 
	};


	console.log('Home', {store})

	return (
		<div>
			{store ? (
				<div>
					<div className={classes.main}>
						<div className={classes.address}>
							<div className={classes['address__img']}>
								<img src={buildMinIcon} alt="" />
							</div>
							<h1 className={classes['address__title']}>{store.title}</h1>
							<a href="#" className={classes['address__link']}>
								Ваш адресс
							</a>
							<address className={classes['address__main']}>{store.address}</address> 
						</div>
					</div>
					<footer className={classes['footer']}>
						<button onClick={handleExit} className={`${classes.btn} ${classes['btn--secondary']}`}>
							НЕТ
						</button>
						<button onClick={handleYes} className={`${classes.btn} ${classes['btn--primery']}`}>
							ДА
						</button>
						{
							storeList?.filter((item: IStore, index, self) => {
								return index === self.findIndex(t => (
									t.id === item.id
								))
							}).length > 1 && (
								<button onClick={() => navigate('/section')} className={`${classes.btn} ${classes['btn--primery']}`}>
									Сменить секцию
								</button>
							)
						}
					</footer>
				</div>
			) : (
				<div style={{ height: '100vh' }}>
					<div>
						<div className="row">
							<div className="column-xs-12" style={{ textAlign: 'center' }}>
								Вы не связанны ни с одним магазином
							</div>
							<div className="column-xs-12">
								{' '}
								<Button size="lg" onClick={handleExit} style={{ margin: 'auto' }}>
									Выйти
								</Button>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default Home;
