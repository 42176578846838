import React from 'react';
import useFetch from 'use-http';

import Table from 'components/UI/Table';
import CounterpartyForm from 'components/CounterpartyForm';

const headers = [{ value: 'id', title: '', hidden: true }, { value: 'title', title: 'Название контрагента' }];

export type CounterPartyType = { 
	createdAt:  string;
	id:  string;
	title:  string;
	updatedAt:  string;
}

export default function Counterparty() {
	const { get, loading, error, response } = useFetch();
	const [data, setData] = React.useState([]);

	const [filter, setFilter] = React.useState<string>('')
	const [filteredData, setFilteredData] = React.useState([]);

	const load = async () => {		
		const result = await get(`/api/counterparty`);
		if (result?.length) {
			setData(result);
		}
	};

	React.useEffect(() => {
		const filtered_data = data.filter((item:CounterPartyType) => {
			let bool: boolean = true
			if (filter) {
				const regex = new RegExp(filter, 'i');
				bool = item?.title.match(regex) !== null
			}
			return filter ? bool : true
		})
		setFilteredData(filtered_data);
	}, [filter, data]);


	React.useEffect(() => {
		load();
	}, []);

	return (
		<div>
			{data && (
				<div>
					{error && 'Error!'}
					{loading && 'Загрузка...'}
					<CounterpartyForm init={load} setFilter={setFilter} data={data}/>

					<Table headers={headers} rows={filteredData} />
				</div>
			)}
		</div>
	);
}
