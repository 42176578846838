import React from 'react';
import useFetch from 'use-http';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import Textarea from 'components/UI/Textarea';
import Button from 'components/UI/Button';
import { StatusEnum } from 'types/banners';

const host = process.env.REACT_APP_BACKEND_URL;

const RejectForm = ({
	row,
	init,
	postfix,
	setDisplay,
}: {
	row: any;
	init: () => Promise<void>;
	postfix: string;
	setDisplay: any;
}) => {
	const { patch, loading, error, response } = useFetch();

	const { handleSubmit, formState, control, register } = useForm<any>();

	const onSubmit: SubmitHandler<any> = async data => {
		await patch(`/api/banners/${data.id}`, data);
		await init();
	};

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			{error && 'Ошибка'}
			{loading && 'Загрузка...'}
			{response.ok && 'Сохранено'}
			<div className="row">
				<div className="column-xs-9">
					<Controller
						name={`comment${postfix}`}
						control={control}
						rules={{ required: 'Обязательное поле' }}
						render={({ field, fieldState }) => (
							<Textarea
								onChange={field.onChange}
								onBlur={field.onBlur}
								name={field.name}
								error={fieldState.error}
								label="Причина отклонения"
								defaultValue={field.value}
							/>
						)}
					/>
				</div>
				<input type="hidden" value={StatusEnum.reject} {...register(`status${postfix}`)} />
				<input type="hidden" value={row.id} {...register('id')} />
				<div className="column-xs-3">
					<div style={{ marginTop: '36px' }}>
						<Button type="submit" size="sm">
							Отправить
						</Button>
						<Button type="submit" size="sm" onClick={() => setDisplay(false)}>
							Отменить
						</Button>
					</div>
				</div>
			</div>
		</form>
	);
};

export default function OperationFrom({
	row,
	init,
	postfix,
}: {
	row: any;
	init: () => Promise<void>;
	postfix: string;
}) {
	const [display, setDisplay] = React.useState(false);

	const { patch, loading, error, response } = useFetch();

	const onSubmit = async () => {
		const data = {
			[`status${postfix}`]: StatusEnum.accept,
		};
		await patch(`/api/banners/${row.id}`, data);
		await init();
	};

	return (
		<div style={{ width: '150px' }}>
			<div>
				{!display && (
					<Button onClick={onSubmit} style={{ background: 'rgb(83, 195, 92)' }} size="sm">
						Принять
					</Button>
				)}

				{!display && (
					<Button onClick={() => setDisplay(!display)} style={{ background: 'rgb(195, 83, 83)' }} size="sm">
						Отклонить
					</Button>
				)}
				{display && <RejectForm row={row} init={init} postfix={postfix} setDisplay={setDisplay} />}
			</div>
		</div>
	);
}
