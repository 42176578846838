import { FetchData } from 'use-http';

export const downloadFile = async <TData>(post: FetchData<TData>, files: FileList) => {
	if (files instanceof FileList) {
		const data = new FormData();
		data.append('file', files[0]);
		const result = await post('api/file/download', data);
		return result;
	}
	return files;
};

export const tokenDecode = (token:string|null) => {
	if (token) {
		const base64UrlasArr = token.split('.');
		const base64Url = token.split('.')[1];
		const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		const jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
			return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
		}).join(''));

		// Парсим полученный JSON-объект
		const payload = JSON.parse(jsonPayload);	
		return payload
	}
	return null	
}