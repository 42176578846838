import React from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

const ZoomComponent = ({
	src,
	alt = '',
	width = 'auto',
	height = '50px',
}: {
	src: string;
	alt?: string;
	width?: string;
	height?: string;
}) => (
	<Zoom transitionDuration={10} zoomMargin={20}>
		<img alt={alt} src={src} width={width} className="img" style={{ maxHeight: height }} />
	</Zoom>
);

export default ZoomComponent;
