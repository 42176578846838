import React from 'react';
import useFetch from 'use-http';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import classes from 'components/Layout/styles.module.scss';
import buildMinIcon from 'assets/img/pic/build-min.png';
import { useUnit } from 'effector-react';
import { $user } from 'models/user';
import useLocalStorage from 'use-local-storage';
import { IStore } from 'dto/store.dto';

const groupByReject = (data: any) => {
	const temp = data.reduce((acc: any, item: any) => {
		if (!acc[item.placementAt]) {
			acc[item.placementAt] = item;
		} else {
			acc[item.placementAt] = { ...item, reject: Number(acc[item.placementAt].reject) + Number(item.reject) };
		}
		return acc;
	}, {});
	return Object.values(temp);
};

export default function Passport() {
	// const { store, user } = useLayoutState();
	const user = useUnit($user);
	const [store] = useLocalStorage<IStore | undefined>(`store`, undefined);

	const navigate = useNavigate();

	const { get, loading, error, response } = useFetch();

	const [passports, setPassports] = React.useState<any>([]);
	const [filter, setFilter] = React.useState(true);

	async function load() {
		if (store) {
			const result = await get(`/api/passport/reject?storeId=${store.id}`);
			if (result) {
				setPassports(groupByReject(result));
			}	
		}
	}

	React.useEffect(() => {
		if (store && user) {
			load();
		}
	}, [store, user]);

	return (
		<div>
			{store && (
				<div>
					<header className={classes.header}>
						<h1 className={classes['time-header']}>Фотоотчеты</h1>
						<div className={classes['header-address']}>
							<div className={classes['header-address__pic']}>
								<img src={buildMinIcon} alt="" />
							</div>
							<div className={classes['header-address__main']}>
								<div className={classes['header-address__name']}>{store.title}</div>
								<address className={classes['header-address__address']}>{store.address}</address>
							</div>
						</div>
					</header>
					<div className={classes.main}>
						<div className={classes.passports}>
							{passports &&
								passports
									.filter((i: any) => (filter ? +i.reject > 0 : +i.reject >= 0))
									.map((i: any) => {
										const handleClick = (placementAt: string) => {
											navigate(`/places?placementAt=${placementAt}`);
										};
										return (
											<a
												key={i.placementAt}
												onClick={() => handleClick(i.placementAt)}
												className={`${classes['passports-item']} ${
													classes[`passports-item--${+i.reject === 0 ? 'success' : 'proccess'}`]
												}`}
											>
												{moment.utc(i.placementAt).format('MMM YY')}
											</a>
										);
									})}
						</div>
					</div>
					<footer className={classes.footer}>
						<button onClick={() => setFilter(!filter)} className={`${classes.btn} ${classes['btn--secondary']}`}>
							УСПЕШНЫЕ
						</button>
					</footer>
				</div>
			)}
		</div>
	);
}
