import React from 'react';
import useFetch from 'use-http';
import Button from 'components/UI/Button';
import Input from 'components/UI/Input';
import { useForm, Controller } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
export interface FormInputs {
	beginAt: string;
	endAt: string;
	[key: string]: string;
}

export default function Report() {
	const validationSchema = Yup.object().shape({
		beginAt: Yup.date().required('Дата начала обязательна'),
		endAt: Yup.date()
			.nullable()
			.required('Дата конца обязательна')
			.when('beginAt', (beginAt, schema) => {
				return schema.test(
				'is-greater',
				'Дата начала не может быть больше даты конца',
				function (value:any) {
					return !beginAt || !value || value > beginAt;
				}
				);
			}),
	  });	

	const { get, loading, error, response } = useFetch({ responseType: 'blob' });

	const { handleSubmit, formState, control } = useForm<any>({ defaultValues:{}, resolver: yupResolver(validationSchema) });

    const onSubmit = React.useCallback(
		async(data:FormInputs) => {
			const queryString = Object.keys(data)
				.filter(key => data[key])
				.map(key => `${encodeURIComponent(key)}=${encodeURIComponent(moment(data[key]).format('YYYY-MM-DD'))}`)
				.join('&');

			get(`api/import/report1?${queryString}`)
				.then((response: Blob) => {
					const href = URL.createObjectURL(response);

					const link = document.createElement('a');
					link.href = href;
					link.setAttribute('download', 'report1.xlsx');
					document.body.appendChild(link);
					link.click();

					document.body.removeChild(link);
					URL.revokeObjectURL(href);
				})
				.catch((error:any) => {
					console.error(error)
				});
			},
		[],
	);
	return (
		<div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="row">
					<div className="column-md-4" style={{marginTop: '45px'}}>
						<h3>Бухгалтерский контроль</h3>
						<p>{error ? <p>{error.message}</p> : ''}</p>
					</div>
					<div className="column-md-2">
						<Controller
							name="beginAt"
							control={control}
							render={({ field, fieldState }) => {
								return (
									<Input
										onChange={field.onChange}
										onBlur={field.onBlur}
										name={field.name}
										error={fieldState.error}
										label="Дата начала"
										type="date"
										value={field.value}
									/>
								)
							}}
						/>
					</div>
					<div className="column-md-2">
					<Controller
							name="endAt"
							control={control}
							render={({ field, fieldState }) => (
								<Input
									onChange={field.onChange}
									onBlur={field.onBlur}
									name={field.name}
									error={fieldState.error}
									label="Дата конца"
									type="date"
								/>
							)}
						/>
					</div>
					<div className="column-md-4" style={{marginTop: '36px'}}>
						<Button disabled={loading} type="submit" size="md">
							Скачать
						</Button>
					</div>
				</div>
			</form>
		</div>
	);
}
