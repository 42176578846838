import React from 'react';
import { Link, useMatch, useResolvedPath } from 'react-router-dom';
import type { LinkProps } from 'react-router-dom';
import ManPeoplePerson from 'assets/img/svg/man_people_person.svg';
import Logo from 'assets/img/svg/familia.svg';
import UsersIcon from 'assets/img/svg/users.svg';
import ReportIcon from 'assets/img/svg/report.svg';
import QuestionIcon from 'assets/img/svg/question.svg';
import ReportsIcon from 'assets/img/svg/reports.svg';
import classes from './styles.module.scss';
import useOnClickOutside from 'hooks/useOnClickOutside';

export interface navLinkItem {
	id: number;
	title: string;
	path: string;
	img: string;
	scope: string;
	sub: navLinkItem[];
}

interface ILinkProps extends LinkProps {
	setOpensub: React.Dispatch<React.SetStateAction<boolean>>;
}

const navLinksList = [
	{ id: 1, title: 'Список магазинов', path: '/admin/', img: UsersIcon, scope: 'admin', sub: [] },
	{
		id: 2,
		title: 'Справочники',
		path: '/admin/',
		img: UsersIcon,
		scope: 'admin',
		sub: [
			{ id: 21, title: 'Контрагент', path: '/admin/directory/counterparty', img: UsersIcon, scope: 'admin', sub: [] },
			{ id: 22, title: 'Типовые места', path: '/admin/directory/example', img: UsersIcon, scope: 'admin', sub: [] },
		],
	},
	{ id: 3, title: 'Отчет по РД', path: '/admin/stores/statuses', img: ReportIcon, scope: 'admin', sub: [] },
	{ id: 4, title: 'Нет рекламы', path: '/admin/banners', img: QuestionIcon, scope: 'admin', sub: [] },
	{ id: 5, title: 'Отчеты', path: '/admin/report', img: ReportsIcon, scope: 'admin', sub: [] },
];

function CustomLinkSub({ children, to, setOpensub }: ILinkProps) {
	const resolved = useResolvedPath(to);
	const match = useMatch({ path: resolved.pathname, end: true });

	return (
		<li>
			<Link
				to={to}
				onClick={() => setOpensub(false)}
				className={`${classes['main-menu-sublink']}  ${match && classes.active}`}
			>
				{children}
			</Link>
		</li>
	);
}

function SubMenu({ item }: { item: navLinkItem }) {
	const [opensub, setOpensub] = React.useState(false);

	const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
		event.preventDefault();
		setOpensub(true);
	};

	const refMenu = React.useRef<HTMLUListElement>(null);
	useOnClickOutside(refMenu, () => {
		setOpensub(false);
	});

	return (
		<>
			<a
				href="#sub"
				className={`${classes['main-menu-link']}  ${classes['main-menu-link--drop']} ${opensub && classes.open}`}
				onClick={handleClick}
			>
				<span className={classes['main-menu-link__icon']}>
					<img alt="icon" src={ManPeoplePerson} style={{ filter: 'brightness(0) invert(1)' }} />
				</span>
				<span className={classes['main-menu-link__txt']}>{item.title}</span>
			</a>
			<ul className={classes['main-menu-sublist']} ref={refMenu}>
				{item.sub.map((item: navLinkItem) => (
					<CustomLinkSub to={item.path} key={item.id} setOpensub={setOpensub}>
						{item.title}
					</CustomLinkSub>
				))}
			</ul>
		</>
	);
}

function MainMenuItem({ item }: { item: navLinkItem }) {
	const resolved = useResolvedPath(item.path);
	const match = useMatch({ path: resolved.pathname, end: true });

	return (
		<li>
			{item.sub && item.sub.length > 0 ? (
				<SubMenu item={item} />
			) : (
				<Link to={item.path} className={`${classes['main-menu-link']} ${match && classes.active}`}>
					<span className={classes['main-menu-link__icon']}>
						<img alt="icon" src={item.img} style={{ filter: 'brightness(0) invert(1)' }} />
					</span>
					<span className={classes['main-menu-link__txt']}>{item.title}</span>
				</Link>
			)}
		</li>
	);
}

export default function Sidebar() {
	return (
		<div className={classes.sidebar}>
			<div className={classes.sidebar__header}>
				<a href="/" className={classes.logo}>
					<img src={Logo} alt="" />
					<img src={Logo} alt="" />
				</a>
			</div>
			<div className={classes.sidebar__main}>
				<nav className={classes['main-menu']}>
					<ul>
						{navLinksList.map(item => (
							<MainMenuItem item={item} key={item.id} />
						))}
					</ul>
				</nav>
			</div>
			<div className={classes.sidebar__footer}>
				<div className={classes.copyright}>©️ 2000-{new Date().getFullYear()} Familia</div>
			</div>
		</div>
	);
}
