import Layout from 'components/Layout';
import styles from './styles.module.scss';
import Button from 'components/UI/Button';
import { delToken } from 'models/user';
import { useKeycloak } from '@react-keycloak/web';
import useLocalStorage from 'use-local-storage';
import { IStore } from 'dto/store.dto';


export default function Forbidden() {
	const { keycloak } = useKeycloak();
	const [store, setStore] = useLocalStorage<IStore | undefined>(`store`, undefined);

	const handleLogout = () => {
        delToken()
		setStore(undefined)
        keycloak.logout(); 
	}
    
	return (
		<Layout>
			<div className={`${styles.forbidden} row`}>
				<div className='column-md-7'>У вас недостаточно прав для получения данной функциональности. Обратитесь в Сервис деск компании.</div>
				<div className='column-md-7'><Button size='lg' style={{margin: 'auto'}} variant="secondary" onClick={handleLogout}>Выйти</Button></div>
			</div>
		</Layout>
	)
};