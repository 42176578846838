import React from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

const DoubleZoom = ({
	src1 = null,
	src2 = null,
	alt = '',
	width = 'auto',
	height = '50px',
}: {
	src1?: string | null;
	src2?: string | null;
	alt?: string;
	width?: string;
	height?: string;
}) => (
	<Zoom transitionDuration={10} zoomMargin={20}>
		<Zoom wrapStyle={{ width: '50%' }}>
			{src1 && <img alt={alt} src={src1} className="img" style={{ maxHeight: height }} />}
		</Zoom>
		<Zoom wrapStyle={{ width: '50%' }}>
			{src2 && <img alt={alt} src={src2} className="img" style={{ maxHeight: height }} />}
		</Zoom>
	</Zoom>
);
export default DoubleZoom;
