import React from 'react';
import useFetch from 'use-http';
import { useParams, useNavigate } from 'react-router-dom';
import Table from 'components/UI/Table';
interface RDStatus {
	countpassport: string;
	email: string;
	emailManager: string;
	id: string;
	manager: string;
	notBanner: string;
	number: string;
	reject: string;
}

export default function StoreStatusesByManager() {
	const { emailManager } = useParams();
	const navigate = useNavigate();

	const { get, loading, error, response } = useFetch();
	const [data, setData] = React.useState([]);

	const load = async () => {
		const result = await get(`/api/stores/statusesByManager?emailManager=${emailManager}`);
		setData(result.filter((item: RDStatus) => +item.notBanner !== 0 || +item.reject !== 0));
	};

	React.useEffect(() => {
		load();
	}, []);

	const headers = [
		{
			value: 'number',
			title: 'Номер магазина',
			formatted: React.useCallback(
				(row: any) => {
					const routeChange = () => {
						const path = `/admin?storeNumber=${row.number}`;
						navigate(path);
					};
					return (
						<a href="#" style={{textDecoration: 'underline'}} onClick={routeChange}>
							{row.number}
						</a>
					);
				},
				[navigate],				
			),			
		},
		{ value: 'reject', title: 'Не принятые паспорта' },
		{ value: 'notBanner', title: 'Нет фото' },
	];

	return (
		<div>
			{data && (
				<div>
					{error && 'Error!'}
					{loading && 'Загрузка...'}
					<Table headers={headers} rows={data} />
				</div>
			)}
		</div>
	);
}
