import React from 'react';
import useFetch from 'use-http';
import { useNavigate } from 'react-router-dom';
import Paginate from 'components/UI/Paginate';
import usePaginate from 'hooks/usePaginate';
import Table from 'components/UI/Table';
import Input from 'components/UI/Input';
import Button from 'components/UI/Button';
import { useSearchParams } from "react-router-dom";
import moment from 'moment';
import { TypeAllocationEnum, getTypeAllocation } from 'types/places';
import { GroupsEnum } from 'types/users';
import { $user } from 'models/user';
import { useUnit } from 'effector-react';

const perPage = 10;

export default function Stores() {
	const { get, loading, error } = useFetch();
	const user = useUnit($user);
	const [searchParams, setSearchParams] = useSearchParams();

	const [data, setData] = React.useState([]);
	const { pageCount, offset, setCount, handlePageClick } = usePaginate(perPage);

	const load = React.useCallback(
		async(filter = '', storeNumber: string| null) => {
				const result = await get(`/api/stores?offset=${offset}&limit=${perPage}&filter=${filter}&storeNumber=${storeNumber}`);
				if (result?.rows) {
					setData(result.rows.map((row: any) => ({ ...row, uri: row?.preview?.uri ? row.preview.uri : null })));
					setCount(result.count);
				}
			},
		[offset, perPage],
	);
	
	React.useEffect(() => {
		const storeNumber = searchParams.get("storeNumber") ? searchParams.get("storeNumber") : '';
		load('', storeNumber);		
	}, [offset, perPage, searchParams]);

	const navigate = useNavigate();

	const headers = [
		{ value: 'number', title: 'Номер' },
		{ value: 'counterpartyTitle', title: 'Контрагент' },
		{ value: 'title', title: 'Название' },
		{ value: 'address', title: 'Адрес' },
		{ 
			value: 'typeAllocation', 
			title: 'Тип размещения рекламы', 
			hidden: 
			!!user 
			&& Array.isArray(user.groups) 
			&& user.groups.indexOf(GroupsEnum.ACCOUNTANT) > -1,
			formatted: React.useCallback(
				(row: any) => {
					let message = getTypeAllocation(TypeAllocationEnum.mix)
					if (row?.typeAllocation === TypeAllocationEnum.paid) {
						message = getTypeAllocation(TypeAllocationEnum.paid)
					} else if (row?.typeAllocation === TypeAllocationEnum.free) {
						message = getTypeAllocation(TypeAllocationEnum.free)
					}
					return message
				},
				[],
			) 			
		},
		{ value: 'manager', title: 'Руководитель' },
		{ 
			value: 'lastActivity', 
			title: 'Последняя активность',
			formatted: React.useCallback(
				(row: any) => {
					return row?.lastActivity ? moment(row?.lastActivity).format('DD.MM.YYYY'): <></>
				},
				[],
			) 
		},
		{ 
			value: 'dateOpen', 
			title: 'Дата открытия',
			formatted: React.useCallback(
				(row: any) => {
					return row?.dateOpen ? moment(row?.dateOpen).format('DD.MM.YYYY'): <></>
				},
				[],
			) 
		},		
		{
			value: 'operation',
			title: '',
			width: '300px',
			formatted: React.useCallback(
				(row: any) => {
					const routeChangeOne = () => {
						const path = `/admin/stores/${row.id}/places`;
						navigate(path);
					};
					const routeChangeTwo = () => {
						const path = `/admin/stores/${row.id}/counterparty/${row.counterpartyId}`;
						navigate(path);
					};

					return (
						<div className="row">
							<div className="column-xs-6">
								<Button size="sm" variant="secondary" onClick={routeChangeOne}>
									Места размещения
								</Button>
							</div>
							<div className="column-xs-6">
								{row.counterpartyId && (
									<Button size="sm" variant="secondary" onClick={routeChangeTwo}>
										Паспорта
									</Button>
								)}
							</div>
						</div>
					);
				},
				[navigate],
			),
		},
	];

	const handleChange = async (event: any) => {
		await load(event.target.value, '');
	};

	const handleChangeNumber = async (event: any) => {
		await load('',event.target.value);	
	};

	return (
		<div>
			{error && 'Error!'}
			<div className="row">
				<div className="column-md-2">
					<Input onChange={handleChangeNumber} name="filter" label="Поиск только по номеру" />
				</div>
				<div className="column-md-2">
					<Input onChange={handleChange} name="filter" label="Поиск по всем данным" />
				</div>
			</div>
			<Table headers={headers} rows={data} />
			{pageCount > 1 && <Paginate handlePageClick={handlePageClick} pageCount={pageCount} />}
		</div>
	);
}
