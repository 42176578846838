import React from 'react';
import { Outlet } from 'react-router-dom';
import classes from './styles.module.scss';

export default function Layout({ children }: { children?: React.ReactNode }) {
	return (
		<div className={`${classes.wrapper} ${classes['wrapper--bg']}`}>
			{React.isValidElement(children) ? children : <Outlet />}
		</div>
	);
}
