import React from 'react';
import useFetch from 'use-http';
import { useNavigate, useLocation } from 'react-router-dom';

import { StatusEnum } from 'types/banners';
import classes from 'components/Layout/styles.module.scss';
import buildMinIcon from 'assets/img/pic/build-min.png';
import moment from 'moment';
import { useUnit } from 'effector-react';
import { $user } from 'models/user';
import useLocalStorage from 'use-local-storage';
import { IStore } from 'dto/store.dto';

export default function Places() {
	// const { store, user } = useLayoutState();
	const user = useUnit($user);
	const [store] = useLocalStorage<IStore | undefined>(`store`, undefined);

	const { search } = useLocation();
	const query = new URLSearchParams(search);
	const placementAt = query.get('placementAt');

	const { get, loading, error, response } = useFetch();

	const [passports, setPassport] = React.useState<any>([]);
	const [places, setPlaces] = React.useState([]);
	const [photos, setPhotos] = React.useState([]);

	const [banners, setBanners] = React.useState([]);

	const navigate = useNavigate();

	async function load() {
		if (store) {
			const result = await get(`/api/passport?storeId=${store.id}&placementAt=${placementAt}`);
			if (result?.length) {
				const places = result.map((i: any) => i.places.join());
				setPassport(result);
				const placesList = await get(`/api/places?id=${places.join()}`);
				setPlaces(placesList);
				const passportIds = result.map((i: any) => i.id);
				const bannersList = await get(`/api/banners?passportId=${passportIds.join()}`);
				setBanners(bannersList.rows);
			}	
		}
	}

	React.useEffect(() => {
		if (store && user) {
			load();
		}
	}, [store, user]);

	React.useEffect(() => {
		if (passports?.length > 0 && places?.length > 0) {
			const result: any = [];
			for (let i = 0; i < passports.length; i = i + 1) {
				const passport = passports[i];
				for (let j = 0; j < places.length; j = j + 1) {
					const place: any = places[j];
					const banner: any = banners.find((banner: any) => Number(banner.placeId) === Number(place.id));
					if (passport?.places.indexOf(+place.id) > -1) {
						result.push({ passport, place, banner });
					}
				}
			}
			setPhotos(result);
		}
	}, [passports, places, banners]);

	return (
		<div>
			{store && (
				<div>
					<header className={classes.header}>
						<h1 className={classes['time-header']}>{placementAt && moment.utc(placementAt).format('MMMM YYYY')}</h1>
						<div className={classes['header-address']}>
							<div className={classes['header-address__pic']}>
								<img src={buildMinIcon} alt="" />
							</div>
							<div className={classes['header-address__main']}>
								<div className={classes['header-address__name']}>{store.title}</div>
								<address className={classes['header-address__address']}>{store.address}</address>
							</div>
						</div>
					</header>
					<div className={classes.main}>
						<div className={classes.photolocations}>
							{photos.map((photo: any) => {
								const { passport, place, banner } = photo;
								let status = '--add';
								if (banner && banner['file.id']) {
									status =
										banner.statusMrkt === StatusEnum.reject || banner.statusAccnt === StatusEnum.reject
											? '--attention'
											: banner.statusMrkt === StatusEnum.pending || banner.statusAccnt === StatusEnum.pending
											? '--process'
											: '--success';
								}
								const disabled =
									banner && banner['file.id'] && !(banner.statusMrkt === StatusEnum.reject || banner.statusAccnt === StatusEnum.reject);

								return (
									<a
										onClick={disabled ? () => ({}) : () => navigate(`/photo/${passport.id}/${place.id}`)}
										key={place.id}
										className={`${classes['photolocations-item']} ${classes[`photolocations-item${status}`]}`}
									>
										{place['example.title']}
									</a>
								);
							})}
						</div>
					</div>
					<footer className={classes.footer}>
						<button onClick={() => navigate('/support')} className={`${classes.btn} ${classes['btn--secondary']}`}>
							Есть проблема?
						</button>
						<button onClick={() => navigate('/passports')} className={`${classes.btn} ${classes['btn--primery']}`}>
							Список отчетов
						</button>
					</footer>
				</div>
			)}
		</div>
	);
}
