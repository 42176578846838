import { useLocation } from 'react-router-dom';

import Avatar from 'assets/img/svg/avatar.svg';
import Topbar from '../Topbar';
// import { useLayoutState } from '../../../LayoutContext';
import classes from './styles.module.scss';
import { useUnit } from 'effector-react';
import { $user } from 'models/user';

const host = process.env.REACT_APP_BACKEND_URL;
// TODO временно
const navLinksList = [
	{ id: 11, title: 'Список пользователей', path: '/admin/users', scope: 'admin' },
	{ id: 12, title: 'Добавить пользователя', path: '/admin/create', scope: 'admin' },
	{ id: 13, title: 'Добавить план адаптации', path: '/admin/upload', scope: 'admin' },
	{ id: 14, title: 'Тесты и курсы', path: '/admin/questions', scope: 'admin' },
	{ id: 15, title: 'Ответы', path: '/admin/answers', scope: 'admin' },
	{ id: 16, title: 'Шаблоны стажировок', path: '/admin/internship', scope: 'admin' },
];

export default function Header() {
	const user = useUnit($user);
	// const { user } = useLayoutState();
	const location = useLocation();

	const found = navLinksList.find(item => item.path === location.pathname);

	return (
		<header className={classes.header}>
			<div className={classes.header__main}>
				<h2>{found?.title}</h2>
			</div>
			{user && (
				<div className={classes.header__bar}>
					<div className={classes['header__bar-item']}>
						<div className={`${classes['profile-bar']} profile-bar`}>
							<div className={classes['profile-bar__wrap']}>
								<div className={classes['profile-bar__main']}>
									<div className={classes['profile-bar__name']}>{user.secondname}</div>
									<div className={classes['profile-bar__sub']}>{user.firstname}</div>
								</div>
								<div className={classes['profile-bar__avatar']}>
									<div className={classes['avatar avatar--md']}>
										<img width="50px" src={user['avatar.uri'] ? host + user['avatar.uri'] : Avatar} alt="" />
									</div>
								</div>
							</div>
							<Topbar />
						</div>
					</div>
				</div>
			)}
		</header>
	);
}
