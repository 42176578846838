
export interface IPlacesResponse {
	beginAt: Date;
	id: string;
    endAt: Date;
	costAllocation?:number;
}


export enum TypeAllocationEnum {
	paid = 'paid',
	free = 'free',
	mix = 'mix',
}

export const getTypeAllocation = (type: string): string => {
	switch (type) {
		case TypeAllocationEnum.paid:
			return 'Платно';
		case TypeAllocationEnum.free:
			return 'Входит в стоимость аренды';
		case TypeAllocationEnum.mix:
			return 'Входит в стоимость аренды/Платно';		
		default:
			return 'Неизвестный тип';
	}
};