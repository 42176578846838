import React, { useEffect } from 'react';

const useOnClickOutside = (ref: React.RefObject<any>, handler: (event?: MouseEvent | TouchEvent) => void): void => {
	useEffect(() => {
		const listener = (event: MouseEvent | TouchEvent): void => {
			if (ref.current && ((ref.current.contains && !ref.current.contains(event.target)) || !ref.current.contains)) {
				handler(event);
			}
		};

		document.addEventListener('mousedown', listener);
		document.addEventListener('touchstart', listener);

		return (): void => {
			document.removeEventListener('mousedown', listener);
			document.removeEventListener('touchstart', listener);
		};
	}, [ref, handler]);
};

export default useOnClickOutside;
