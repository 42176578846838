import React from 'react';
import { Outlet } from 'react-router-dom';
// import { RolesEnum } from 'types/users';
import Header from './Header';
import Sidebar from './Sidebar';
import classes from './styles.module.scss';
// import { useLayoutState } from '../../LayoutContext';
import { useUnit } from 'effector-react';
import { $user } from 'models/user';

export default function AdminLayout() {
	const user = useUnit($user);
	// const { user } = useLayoutState();

	// if (
	// 	user &&
	// 	!(
	// 		user.roles.includes(RolesEnum.Accountant) ||
	// 		user.roles.includes(RolesEnum.Retail) ||
	// 		user.roles.includes(RolesEnum.Marketer)
	// 	)
	// ) {
	// 	window.location.href = '/';
	// }

	return (
		<div className={classes.wrapper}>
			<Header />
			<Sidebar />
			<main className={classes.main}>
				<div className={classes.content}>
					<Outlet />
				</div>
			</main>
		</div>
	);
}
