import React from 'react';
import useFetch from 'use-http';
import Paginate from 'components/UI/Paginate';
import usePaginate from 'hooks/usePaginate';
import { useNavigate } from 'react-router-dom';
import Table from 'components/UI/Table';
import Button from 'components/UI/Button';
import moment from 'moment';
import Select from 'components/UI/Select';
import Input from 'components/UI/Input';

const perPage = 25;

const years = [
	{ value: -1, label: 'Сбросить' },
	{ value: 2022, label: '2022' },
	{ value: 2023, label: '2023' },
	{ value: 2024, label: '2024' },
	{ value: 2025, label: '2025' },
	{ value: 2026, label: '2026' },
	{ value: 2027, label: '2027' },
	{ value: 2028, label: '2028' },
	{ value: 2029, label: '2029' },
	{ value: 2030, label: '2030' },
];

const months = [
	{ value: -1, label: 'Сбросить' },
	{ value: 1, label: 'Январь' },
	{ value: 2, label: 'Февраль' },
	{ value: 3, label: 'Март' },
	{ value: 4, label: 'Апрель' },
	{ value: 5, label: 'Май' },
	{ value: 6, label: 'Июнь' },
	{ value: 7, label: 'Июль' },
	{ value: 8, label: 'Август' },
	{ value: 9, label: 'Сентябрь' },
	{ value: 10, label: 'Октябрь' },
	{ value: 11, label: 'Ноябрь' },
	{ value: 12, label: 'Декабрь' },
];

export default function Banners() {
	const { get, loading, error } = useFetch();

	const [data, setData] = React.useState([]);
	const { pageCount, offset, setCount, handlePageClick, setPageCount, setOffset } = usePaginate(perPage);

	const [year, setYear] = React.useState(-1);
	const [month, setMonth] = React.useState(-1);
	const [number, setNumber] = React.useState();

	async function load() {
		const queryParams = {
			offset,
			limit: perPage,
			posted: 0,
			year,
			month,
			number,
		};

		const filteredParams = Object.entries(queryParams)
			.filter(([key, value]) => value !== undefined)
			.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`)
			.join('&');
	  
		const queryString = new URLSearchParams(filteredParams).toString();
		  
		const result = await get(`/api/banners/noAdvertising?${queryString}`)
		if (result?.rows) {
			setData(result.rows);
			setCount(result.count);
		}
	}

	const navigate = useNavigate();

	React.useEffect(() => {
		load();
	}, [offset, perPage, year, month, number]);

	const handleChangeYear = (param: number) => {
		setOffset(0)
		setYear(param);
	};

	const handleChangeMonth = (param: number) => {
		setOffset(0)
		setMonth(param);
	};	

	const handleChangeNumber = async (event: any) => {
		setOffset(0)
		setNumber(event.target.value);	
	};	

	const headers = [
		{ value: 'passportId', hidden: true },
		{ value: 'storeNumber', title: 'Магазин' },
		{
			value: 'placementAt',
			title: 'Пасспорт',
			formatted: (row: any) => moment(row['placementAt']).format('MMM YY'),
		},
		{ value: 'manager', title: 'Руководитель' },
		{
			value: 'operation',
			title: '',
			width: '300px',
			formatted: React.useCallback((row: any) => {
				const routeChange = () => {
					const path = `/admin/stores/${row['storeId']}/passport/${row['passportId']}`;
					navigate(path);
				};
				return (
					<Button size="sm" variant="secondary" onClick={routeChange}>
						Паспорта
					</Button>
				);
			}, []),
		},
	];

	return (
		<div>
			{error && 'Error!'}
			<div className="row">
				<div className="column-md-4">
					<Input onChange={handleChangeNumber} name="filter" label="Поиск только по номеру" />
				</div>

				<div className="column-md-3">
					<Select
						onChange={handleChangeYear}
						options={years.filter(y => year === -1 && y.value <= new Date().getFullYear() || y.value <= new Date().getFullYear())}
						label="Год"
						defaultValue={year}
					/>
				</div>
				<div className="column-md-3">
					<Select onChange={handleChangeMonth} options={months} label="Месяц" defaultValue={month} />
				</div>
			</div>			
			<Table headers={headers} rows={data} />
			{pageCount > 1 && <Paginate handlePageClick={handlePageClick} pageCount={pageCount} />}
		</div>
	);
}
