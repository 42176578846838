import LogoutIcon from 'assets/img/svg/logout.svg';
import classes from '../Header/styles.module.scss';
import { useKeycloak } from "@react-keycloak/web";
import { delToken } from 'models/user';

export default function Logout() {
	const { keycloak } = useKeycloak();

	const handleLogout = async() => {
        delToken()
        keycloak.logout(); 
	};
	
	return (
        <a className={classes['drop-nav-link']} onClick={() => handleLogout()} href="#signin">
            <span className={classes['drop-nav-link__icon']}>
                <img
                    alt="icon"
                    src={LogoutIcon}
                    style={{
                        filter:
                            'invert(27%) sepia(23%) saturate(1058%) hue-rotate(233deg) brightness(98%) contrast(90%)',
                    }}
                />
            </span>
            <span className={classes['drop-nav-link__txt']}>Выйти из аккаунта</span>
        </a>
	);
}
