// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body{margin:0;font-family:-apple-system,BlinkMacSystemFont,\"Segoe UI\",\"Roboto\",\"Oxygen\",\"Ubuntu\",\"Cantarell\",\"Fira Sans\",\"Droid Sans\",\"Helvetica Neue\",sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro,Menlo,Monaco,Consolas,\"Courier New\",monospace}.preview--pkbDJ{max-height:50px}.preview--pkbDJ:hover{transform:scale(5);position:absolute;z-index:1}.ellipsis--JwUqI{white-space:nowrap;text-overflow:ellipsis;overflow:hidden}", "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAAA,KACE,QAAA,CACA,mJAAA,CAGA,kCAAA,CACA,iCAAA,CAGF,KACE,yEAAA,CAIF,gBACE,eAAA,CAEF,sBACE,kBAAA,CACA,iBAAA,CACA,SAAA,CAGF,iBACE,kBAAA,CACA,sBAAA,CACA,eAAA","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',\n    monospace;\n}\n\n.preview {\n  max-height: 50px;\n}\n.preview:hover {\n  transform: scale(5);\n  position: absolute;\n  z-index: 1;\n}\n\n.ellipsis {\n  white-space: nowrap;\n  text-overflow: ellipsis;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"preview": "preview--pkbDJ",
	"ellipsis": "ellipsis--JwUqI"
};
export default ___CSS_LOADER_EXPORT___;
