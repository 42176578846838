import React from 'react';
import useFetch from 'use-http';
import { useParams } from 'react-router-dom';

import Table from 'components/UI/Table';
import PlaceForm from 'components/PlaceForm';
import moment, { Moment } from 'moment';
import ZoomComponent from 'components/UI/Zoom';
import CheckIcon from 'assets/img/svg/checked.svg';
import PenToSquareIcon from 'assets/img/svg/pen-to-square-solid.svg';
import Button from 'components/UI/Button';
import { useNavigate } from 'react-router-dom';
import classes from 'components/AdminLayout/styles.module.scss';
import * as yup from 'yup';
import Select from 'components/UI/Select';
import { IPlacesResponse, TypeAllocationEnum, getTypeAllocation } from 'types/places';
// import { useLayoutState } from '../../../LayoutContext';
import { GroupsEnum, RolesEnum } from 'types/users';
import { $user } from 'models/user';
import { useUnit } from 'effector-react';

const host = process.env.REACT_APP_BACKEND_URL;

const startDate = moment().subtract(3, 'years');

export const CreateSchema = yup.object({
	exampleId: yup.string().required('Поле обязательно к заполнению'),
	counterpartyId: yup.string().required('Поле обязательно к заполнению'),
	file: yup.mixed().required('Поле обязательно к заполнению'),
	beginAt: yup
		.date()
		.min(startDate, `Дата начала не должна быть меньше ${startDate.format('DD.MM.YYYY')}`)
		.required('Поле обязательно к заполнению'),
	endAt: yup
		.date()
		.when('beginAt', (beginAt, schema) => {
			return beginAt && schema.min(beginAt, 'Дата окончания не должна быть меньше даты начала');
		})
		.required('Поле обязательно к заполнению'),
	typeAllocation: yup.string().required('Поле обязательно к заполнению'),
	costAllocation: yup.mixed()
});


const placesOptions = [
	{value: 'all', label: 'Все'},
	{value: 'open', label: 'Актуальные'},
	{value: 'close', label: 'Закрытые'},
]

const filterPlaces = (type:string, item:IPlacesResponse) => {
	const curDate = moment()
	if (type === 'close') {
		return moment(item.endAt) < curDate
	}
	if (type === 'open') {
		return moment(item.endAt) > curDate		
	}
	return true;
}


export default function Places() {
	const { storeId } = useParams();
	// const { user } = useLayoutState();
	const user = useUnit($user);
	const { get, post, loading, error, response } = useFetch();
	const [data, setData] = React.useState<IPlacesResponse[]>([]);
	const [created, setCreated] = React.useState(false)
	const [type,setType] = React.useState('all')

	const navigate = useNavigate();

	const load = async () => {
		let params = '';
		if (storeId) {
			params = `?storeId=${storeId}`;
		}
		const result = await get(`/api/places${params}`);
		setData(result);
	};

	React.useEffect(() => {
		load();
	}, [storeId]);

	const headers = [
		{ value: 'counterparty.title', title: 'Контрагент' },
		{ 
			value: 'example.title', 
			title: (_: any, data: IPlacesResponse[]) => {
				return `Место размещения (${data.length})`;
			},
		},
		{
			value: 'costAllocation',
			hidden: 
			!!user 
			&& Array.isArray(user.groups) 
			&& user.groups.indexOf(GroupsEnum.ACCOUNTANT) > -1,
			title: (_: any, data: IPlacesResponse[]) => {
				const total = data.reduce((acc:number, item:IPlacesResponse) => {
					acc += item.costAllocation ?  +item.costAllocation : 0;
					return acc;
				}, 0)
				return `Тип размещения рекламы (Итого: ${total})`;
			},
			formatted: (row: any) => {
				const cost = row.costAllocation ? +row.costAllocation : 0;
				return row.typeAllocation === TypeAllocationEnum.paid ? cost : getTypeAllocation(TypeAllocationEnum.free);
			},
		},
		{
			value: 'description',
			title: 'Краткое описание',
			formatted: (row: any) => (
				<div title={row['description']} className="ellipsis" style={{ width: '150px' }}>
					{row?.description}
				</div>
			),
		},
		{
			value: 'beginAt',
			title: 'Дата начала',
			formatted: React.useCallback((row: any) => row['beginAt'] && moment(row?.beginAt).format('DD.MM.YYYY'), []),
		},
		{
			value: 'endAt',
			title: 'Дата завершения',
			formatted: React.useCallback((row: any) => row['endAt'] && moment(row?.endAt).format('DD.MM.YYYY'), []),
		},
		{
			title: 'Пример фото',
			value: 'preview.uri',
			formatted: React.useCallback(
				(row: any) => row['preview.uri'] && <ZoomComponent src={host + row['preview.uri']} />,
				[],
			),
		},
		{
			value: 'operation',
			title: '',
			formatted: (row: any) => {
				const routeChange = () => {
					const path = `/admin/places/${row.id}`;
					navigate(path);
				};
				return (
					<Button
						style={{ padding: 0 }}
						size="sm"
						variant={null}
						onClick={routeChange}
						endIcon={<img src={PenToSquareIcon} />}
					></Button>
				);
			},
		},
	];

	const onSubmit = React.useCallback(
		
		async (data: any) => {
			const formData = new FormData();
			formData.append('file', data.file[0]);
			formData.append('exampleId', data.exampleId);
			formData.append('counterpartyId', data.counterpartyId);
			formData.append('beginAt', moment(data.beginAt).format('YYYY-MM-DD'));
			formData.append('typeAllocation', data.typeAllocation);

			if (data.costAllocation) {
				formData.append('costAllocation', data.costAllocation);
			}
			formData.append('endAt', moment(data.endAt).format('YYYY-MM-DD'));
			if (storeId) {
				formData.append('storeId', storeId);
			}
			if (data?.included) {
				formData.append('included', data.included);
			}
			if (data?.description) {
				formData.append('description', data?.description);
			}

			await post('/api/places', formData);
			await load();
			setCreated(true)
		},
		[storeId],
	);

	return (
		<div>
			{response.ok && data && (
				<div>
					{error && 'Error!'}
					{loading && 'Загрузка...'}
					{created && <div className={classes.warn}>Обновление информации в паспортах может занять 5-10 минут</div>}
					{storeId && <PlaceForm onSubmit={onSubmit} schema={CreateSchema} />}

					<div className="row">
						<h1>Фильтр</h1>
					</div>
					<div className="row">
						<div className="column-md-2">
							<Select
								onChange={(value:string) => setType(value)}
								options={placesOptions}
								label="Места размещения"
							/>
						</div>
					</div>
					<Table headers={headers} rows={data.filter((item:IPlacesResponse) => filterPlaces(type, item))} />
				</div>
			)}
		</div>
	);
}
