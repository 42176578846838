import { RolesEnum } from 'types/users';
import classes from './styles.module.scss';

export interface ITableHeader {
	value: string;
	title?: string | ((row: any, data:any) => void);
	width?: string;
	hidden?: boolean;
	formatted?: (row: any) => any;
}

export interface ITableProps<T> {
	headers: ITableHeader[];
	rows: T[];
}

function renderTitle(variable:any, head: any, rows:any) {
	if (typeof variable === "string") {
		return variable
	} else if (typeof variable === "function") {
		const result = variable(head, rows);
		return result
	}	
	return ''
}

const generateUnique = (headers: ITableHeader[], row: any) => {
	return headers.reduce((acc: string, item: ITableHeader) => {
		return acc + (row[item.value] ? row[item.value] : '');
	}, '');
};

export default function Table<T>({ headers, rows }: ITableProps<T>) {
	return (
		<div className={classes['table-overlay']}>
			<table className={classes.table}>
				<thead>
					<tr>
						{headers.map(
							header =>
								!header.hidden && (
									<th style={{ width: header.width ? header.width : 'auto' }} key={header.value}>
										{renderTitle(header?.title, header, rows)}
									</th>
								),
						)}
					</tr>
				</thead>
				{rows.length > 0 ? (
					<tbody>
						{rows.map((row: any) => (
							<tr key={generateUnique(headers, row)}>
								{headers.map(
									header =>
										!header.hidden && (
											<td key={header.value}>{header.formatted ? header.formatted(row) : row[header.value]}</td>
										),
								)}
							</tr>
						))}
					</tbody>
				) : (
					<tbody>
						<tr>
							<td colSpan={headers.length} style={{ textAlign: 'center' }}>
								Записей не найдено.
							</td>
						</tr>
					</tbody>
				)}
			</table>
		</div>
	);
}
