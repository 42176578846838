export enum RolesEnum {
	Admin = 'Admin',
	Accountant = 'Accountant', // Бухгалтер
	Retail = 'Retail', // Розница
	Marketer = 'Marketer', // Маркетолог
}

export enum PositionType {
	Office = 'office', // офис
	Market = 'market', // магазин
}

export enum GroupsEnum {
	ADMIN = "/fpo/fpo. администрирование рекламных мест",
	MARKETER = "/fpo/fpo. проверка паспорта (маркетинг)",
	ACCOUNTANT = "/fpo/fpo. проверка паспорта (бухгалтерия)",
	PASSPORT_UPLOAD = "/fpo/fpo. загрузка паспортов",
	REPORT = "/fpo/fpo. отчет по полученным фотоотчетам",
	CLIENT = "/fpo/fpo. общее",
}

export interface IPosition {
	createdAt: Date;
	id: string;
	title: string;
	type: PositionType;
	unitId: string;
	updatedAt: Date;
}

export interface IUser {
	id: number;
	firstname: string;
	secondname: string;
	middlename: string;
	createdAt: Date;
	updatedAt: Date;
	position: IPosition;
	birthday?: string | undefined;
	roles:string[];
}